@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  color: inherit;
}

input[type=radio] {
  border: 0px;
  width: 5%;
}

/*.drop-shadow {
  text-shadow:
   -0.5px -0.5px 0 #000,  
    0.5px -0.5px 0 #000,
    -0.5px 0.5px 0 #000,
     0.5px 0.5px 0 #000;
}*/